import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import * as ls from "local-storage";

const Private = ({ component: Component, ...rest }: RouteProps) => (
    <Route {...rest} render={
        (props) => (
            (typeof ls.get<string>('token') == 'string')
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/dang-nhap',
                        state: { from: props.location }
                    }} />
        )
    } />
)

export default Private