import * as React from "react"
import {CategoryState} from "../reducer/type/category";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as CategoryAction from "../action/category";
import * as PackageAction from "../action/package";
import {connect} from "react-redux";
import Menu from "../component/Menu";
import Header from "../component/Header";
import LoadingScreen from "../component/LoadingScreen";
import {PackageState, PackageItemInterface, UserItemInterface} from "../reducer/type/package";
import * as $ from 'jquery'
import * as ls from "local-storage";
import api from "../api/api";
import * as AuthAction from "../action/auth";
import {AuthState} from "../reducer/type/auth";
import {API_PATH} from "../constant";
import {Link} from "react-router-dom";

interface StatePropsInterface {
    category: CategoryState,
    package: PackageState,
    auth: AuthState
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        getPackageAction: any,
        userPackageAction: any,
        getInfoAction: any,
        postAutoLogin: any
    }
}

interface ParamPropsInterface {
    location: any,
    history: any
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    package: state.package,
    auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        getPackageAction: PackageAction.getPackageAction,
        userPackageAction: PackageAction.userPackageAction,
        getInfoAction: AuthAction.getInfoAction,
        postAutoLogin: AuthAction.autoLoginAction
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    link0: string,
    link1: string,
    link2: string
}

class Package extends React.Component <PropsInterface, CurrentStateInterface> {

    constructor (props: PropsInterface) {
        super(props)
        this.props.actions.getCategoryAction();
        this.state = {
            loading: true,
            link0: '',
            link1: '',
            link2: ''
        }
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
    }

    componentDidMount() {
        ls.clear();
        let url_string = window.location.href;
        let url = new URL(url_string);
        let phone = url.searchParams.get("phone");
        if (phone !== null && phone !== "") {
            this.props.actions.postAutoLogin(phone, true);
        }

        $('.package-page').css({
            background: '#e1e1e1'
        });
        $('body').css({
            paddingTop: 0
        });
        this.props.actions.getPackageAction();
        // if (typeof ls.get<string>('token') == 'string') {
        //     this.props.actions.userPackageAction();
        // }
        this.props.actions.userPackageAction();
    }

    componentWillUnmount() {
        $('body').css({
            background: 'auto'
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.package.data !== prevProps.package.data) {
            this.setState({
                loading: false
            });
            this.props.package.data.map((item: PackageItemInterface, key) => {
                this.clickRegisterPackage(item.code, key);
            });
        }
        if (this.props.location !== prevProps.location) {
            this.setState({
                loading: true
            });
            this.props.actions.getPackageAction();
        }
    }

    clickRegisterPackage (code, key) {
        if (typeof ls.get<string>('msisdn') == 'string') {
            let tranId = Date.now() + Math.round(Math.random() * 100000);
            let commandCode = 'DK ' + code;
            let packageCode = code;
            let backUrl = 'http://ibolero.vn/goi-cuoc';
            let info = '';

            let link = tranId + "&" + commandCode + '&' + packageCode + '&' + backUrl + '&' + info;

            api.get(API_PATH + "/service/ecbdecrypt", {
                params: {
                    'data': link,
                },
            }).then((response) => {
                if (code == "BL1") {
                    this.setState({
                        link0: 'http://free.mobifone.vn/confirm?sp=9355&link=' + response.data
                    });
                } else if (code == "BL7") {
                    this.setState({
                        link1: 'http://free.mobifone.vn/confirm?sp=9355&link=' + response.data
                    });
                } else if (code == "BL30") {
                    this.setState({
                        link2: 'http://free.mobifone.vn/confirm?sp=9355&link=' + response.data
                    });
                }
            })
            .catch((response) => {
                this.makeHrefRegister(code);
            });
        } else {
            this.makeHrefRegister(code);
        }
    }

    makeHrefRegister(code) {
        if ( navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
            if (code == "BL1") {
                this.setState({
                    link0: 'sms:9355&body=DK '+ code
                });
            } else if (code == "BL7") {
                this.setState({
                    link1: 'sms:9355&body=DK '+ code
                });
            } else if (code == "BL30") {
                this.setState({
                    link2: 'sms:9355&body=DK '+ code
                });
            }
        } else if ( navigator.userAgent.match(/Android/i) ) {
            if (code == "BL1") {
                this.setState({
                    link0: 'sms:+9355?body=DK '+ code
                });
            } else if (code == "BL7") {
                this.setState({
                    link1: 'sms:+9355?body=DK '+ code
                });
            } else if (code == "BL30") {
                this.setState({
                    link2: 'sms:+9355?body=DK '+ code
                });
            }
        }
    }

    render() {
        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let packageView;
        packageView = this.props.package.data.map((item: PackageItemInterface, key) => {

            let link;
            let hideStyle;
            if (this.props.auth.data.package != '') {
                if (this.props.auth.data.package == "BL1" && item.code == "BL1") {
                    link = (<div className='btn login-btn mt-2'>
                        Đã đăng ký
                    </div>)
                    hideStyle = {display: "none"};
                } else if(this.props.auth.data.package == "BL7" && item.code == "BL7") {
                    link = (<div className='btn login-btn mt-2'>
                        Đã đăng ký
                    </div>)
                    hideStyle = {display: "none"};
                } else if(this.props.auth.data.package == "BL30" && item.code == "BL30") {
                    link = (<div className='btn login-btn mt-2'>
                        Đã đăng ký
                    </div>)
                    hideStyle = {display: "none"};
                } else {
                    if (item.code == "BL1") {
                        link = (<a href={this.state.link0} className='btn login-btn mt-2'>
                            Đăng ký
                        </a>)
                    } else if(item.code == "BL7") {
                        link = (<a href={this.state.link1} className='btn login-btn mt-2'>
                            Đăng ký
                        </a>)
                    } else if(item.code == "BL30") {
                        link = (<a href={this.state.link2} className='btn login-btn mt-2'>
                            Đăng ký
                        </a>)
                    }
                }
            } else {
                if (item.code == "BL1") {
                    link = (<a href={this.state.link0} className='btn login-btn mt-2'>
                        Đăng ký
                    </a>)
                } else if(item.code == "BL7") {
                    link = (<a href={this.state.link1} className='btn login-btn mt-2'>
                        Đăng ký
                    </a>)
                } else if(item.code == "BL30") {
                    link = (<a href={this.state.link2} className='btn login-btn mt-2'>
                        Đăng ký
                    </a>)
                }
            }

            return (
                <div className='col-12 mb-3' key={key} style={hideStyle}>
                    <div className="package-item">
                        <div className="package-item-header">{item.name}</div>
                        <div className="package-item-content">
                            <div className='package-item-text' dangerouslySetInnerHTML={{ __html: item.description }} />
                            <div className="text-center">
                                {link}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        let userView;
        if (this.props.auth.data.package != '') {
            let packageDesc = this.props.package.data.map((item:PackageItemInterface) => {
                if (item.code == this.props.auth.data.package) {
                    return (
                        <div className="package-sub" dangerouslySetInnerHTML={{ __html: item.description }} />
                    )
                } else {
                    return null;
                }
            });

            let namePackageLogin;
            if (this.props.auth.data.package == "BL1") {
                namePackageLogin = "Ngày BL1";
            } else if (this.props.auth.data.package == "BL7") {
                namePackageLogin = "Tuần BL7";
            } else if (this.props.auth.data.package == "BL30") {
                namePackageLogin = "Tháng BL30";
            }

            userView = (
                <div>
                    <div className="row package-user">
                        <div className="col-12">
                            <div className="package-user-title mb-2">
                                Bạn đang sử dụng gói cước {namePackageLogin} dịch vụ iBolero. Bạn được:
                            </div>
                            <div>
                                - Nghe nhạc trữ tình, nhạc bolero thả ga tại iBolero MIỄN PHÍ 3G/4G TỐC ĐỘ CAO.<br/>
                                - Nghe, tải nhạc ĐỘC QUYỀN chỉ có tại iBolero<br/>
                                - Tải hàng ngàn video chất lượng full HD không giới hạn
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className='package-title'>
                                Để chuyển đổi sang gói cước khác, bạn vui lòng hủy gói cước hiện tại và ấn để đăng ký:
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            userView = (
                <div className="row mt-4">
                    <div className="col-12">
                        <div className='package-title'>
                            MOBIFONE MỜI BẠN THAM GIA IBOLERO ĐỂ ĐƯỢC:<br/>
                        </div>
                        <div className='package-sub'>
                            - Nghe nhạc trữ tình, nhạc bolero thả ga tại iBolero MIỄN PHÍ 3G/4G TỐC ĐỘ CAO.<br/>
                            - Những bản nhạc ĐỘC QUYỀN chỉ có tại iBolero<br/>
                            - Tải hàng ngàn video chất lượng full HD không giới hạn<br/>
                            - Miễn phí 1 ngày sử dụng cho thuê bao đăng ký lần đầu<br/>
                            (Từ 0h00:00 ngày 10/06/2020 đến hết 23h59:59 ngày 07/09/2020 (90 ngày). Thuê bao đăng ký thành công gói ngày BL1 lần đầu tiên và duy trì thành công 03 ngày liên tiếp (bao gồm ngày đầu đăng ký) sẽ được tặng 1GB data code để truy cập internet )
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div id="container" className="container-fluid overflow-hidden package-page">
                <div className="menu-overlay"/>
                {loadingScreen}

                {userView}

                <div className="row mt-4">
                    {packageView}
                </div>

                {/*<div className="row mt-3 mb-4">*/}
                {/*    <div className="col-12">*/}
                {/*    - Để biết thêm thông tin chi tiết về dịch vụ iBolero, bạn vui lòng soạn HD gửi 9355 hoặc truy cập <Link to="/gioi-thieu">http://ibolero.vn/gioi-thieu.</Link><br/>*/}
                {/*    - Để kiểm tra gói cước đang sử dụng, soạn KT gửi 9355. Lấy lại mật khẩu, soạn MK gửi 9355.<br/>*/}
                {/*    (Các tin nhắn HD, KT, MK là tin nhắn miễn phí)<br/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Package);
